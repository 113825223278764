/**
 * load-dom-purify.js
 * Loads the DOMPurify library for user input cleansing.
 * This script is loaded into minified builds and should
 * be available across all brands.
 * @external https://www.npmjs.com/package/dompurify
 * @example Search for DOMPurify in the following files:
 * - form-utilities.js
 * - typeahead-search.js
 */
((window, document) => {
  function initialise() {
    // load xss purity library
    const loadDOMPurify = document.createElement('script');
    loadDOMPurify.src = 'https://cdnjs.cloudflare.com/ajax/libs/dompurify/3.4.1/purify.min.js';
    loadDOMPurify.type = 'text/javascript';
    document.head.appendChild(loadDOMPurify);
  }

  window.addEventListener('load', initialise, false);
})(window, document);
